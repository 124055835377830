var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          class: _vm.className,
          style: {
            color: _vm.color,
            fontSize: _vm.countSize,
            fontWeight: _vm.countWeight,
            display: _vm.display,
          },
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.prefix))]),
          _c("span", { attrs: { id: _vm.id } }),
          _c("span", [_vm._v(_vm._s(_vm.unit))]),
          _c("span", [_vm._v(_vm._s(_vm.suffix))]),
        ]
      ),
      _vm._t("intro"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }